import Section from "@/components/section";
import Image from "next/image";
import secureDataImg from "@/assets/landingpage/security.svg"; // Replace with your image path

const data = {
  title: "Secure Data Handling",
  content: "Our advanced encryption and GDPR compliance ensure your data is always protected. Trust us to safeguard your information, so you can innovate with confidence.",
};

export default function SecureDataComponent() {
  return (
    <Section className="bg-yellow-400">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4 md:px-8 lg:px-12">
        {/* Left Side - Text Content */}
        <div className="lg:w-1/2 space-y-4 text-center lg:text-left">
          <h2 className="text-2xl md:text-3xl font-semibold text-black">{data.title}</h2>
          <p className="text-lg md:text-xl text-black">{data.content}</p>
        </div>

        {/* Right Side - Image */}
        <div className="lg:w-1/2 flex justify-center mt-8 lg:mt-0">
          <Image
            src={secureDataImg}
            alt="Secure Data Handling"
            width={400} // Adjust width as needed
            height={300} // Adjust height as needed
            style={{objectFit: "contain"}}
            className="rounded-lg"
          />
        </div>
      </div>
    </Section>
  );
}
