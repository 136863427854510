"use client";

import FlickeringGrid from "@/components/magicui/flickering-grid";
import Ripple from "@/components/magicui/ripple";
import Safari from "@/components/safari";
import Section from "@/components/section";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import dashboardImg from '@/assets/dashboard.png';
import VideoComponent from "./video-oneplace";

export default function Component() {
  return (
    <Section
      subtitle="Cut your downtime. Do it all in one place."
      className="py-5" // Removes padding from the Section component if any
    >
      <div className="mx-auto max-w-sm md:max-w-3xl xl:max-w-6xl">
        <VideoComponent/> {/* Removes any padding or margin */}
      </div>
    </Section>
  );
}
