import BlurFade from "@/components/magicui/blur-fade";
import Section from "@/components/section";
import { Card, CardContent } from "@/components/ui/card";
import { Brain, Shield, Zap } from "lucide-react";
import Image from "next/image";
import decision from "../../../../../src/assets/landingpage/benefits/benefits_decision.svg";
import time from "../../../../../src/assets/landingpage/benefits/benefits_time.svg";
import budget from "../../../../../src/assets/landingpage/benefits/benefits_budget.svg";

const benefits = [
  {
    title: "Streamline Your Workflow",
    description:
      "Finish three days of work with just a few clicks, reducing data interpretation time by up to 90%.",
    icon: (<Image
        height={400}
        width={400}
        src={time}
        alt={"time efficiency"}
      />),
  },
  {
    title: "Optimize Your Project Budgeting",
    description:
      "Cut Geo-data handling and associated software costs by over 80%.",
    icon: (<Image
        height={50}
        width={50}
        src={budget}
        alt={"budget optimization"}
      />),
  },
  {
    title: "Enhance Your Decision-Making ",
    description: "Eliminate data fragmentation and silos with high-quality, reliable solutions.",
    icon: (<Image
          height={400}
          width={400}
          src={decision}
          alt={"decision making"}
        />),
  },
];

export default function Component() {
  return (
    <Section
      subtitle="Geotechnical Interpretations Made Effortless"
      className="bg-secondary-foreground bg-opacity-10"
    >
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
      {benefits.map((benefit, index) => (
        <BlurFade key={index} delay={0.2 + index * 0.2} inView>
          <Card className="bg-transparent border-none shadow-none">
            <CardContent className="p-6 space-y-4 flex flex-col items-center justify-center h-full">
              <div className="w-24 h-24 rounded-full flex items-center justify-center">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-semibold text-center">{benefit.title}</h3>
              <p className="text-muted-foreground text-center">{benefit.description}</p>
            </CardContent>
          </Card>
        </BlurFade>
      ))}
    </div>
    </Section>
  );
}